import { Box, Image, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useShallow } from 'zustand/react/shallow'
import { useApiConfig } from '../../../hooks/useApiConfig.js'
import { useStore } from '../../../store/store.js'
import { BotTyping } from '../../Animation/TypingWithIcon/BotTyping'
import DocChipList from '../../DocChip/DocChipList.jsx'
import { synthSettings } from '../../../settings/synth.settings.js'
import { convoSettings } from '../../../settings/convo.settings.js'
import { ThumbBox } from './Thumbs/ThumbBox'
import { sentryUtils } from '../../../lib'

// Streaming feature, once the stream is finished it's being changed to the backend response.
export const BotResponse = ({ isCurrent, convo, handleOpenDoc, bubbleWidth, handleOpenSR, handleCloseSR }) => {
	const {
		setSearchResults,
		setprevSrpRequestId,
		widgetLog,
		widgetSession,
		avatar,
		updateConvo,
		fetchWeb,
		setContactSupportForm
	} = useStore(useShallow(state => ({
		setSearchResults: state.setSearchResults,
		setprevSrpRequestId: state.setprevSrpRequestId,
		widgetLog: state.widgetLog,
		widgetSession: state.widgetSession,
		avatar: state.widgetSession.appearanceConfig.avatar,
		updateConvo: state.updateConvo,
		fetchWeb: state.fetchWeb,
		setContactSupportForm: state.setContactSupportForm
	})));
	const hasSearchResults = Array.isArray(convo.searchResults)
	const isSearchResultNotEmpty = hasSearchResults && convo.searchResults.length > 0

	const [isMultiline, setIsMultiline] = useState(false)
	const [thumbStatus, setThumbStatus] = useState(null)
	const textRef = useRef()
	const resizeObserver = useRef(null)
	const msgResponseRef = useRef(null)
	const avatarRef = useRef(null)

	const { apiBaseUrl } = useApiConfig()
	const msg = transformBotMessage(convo.botResponse)

	let queryMessage = convoSettings.DEFAULT_QUERY_MESSAGE

	// Displaying the generated search query from the synthesis filters
	if (hasSearchResults && Array.isArray(convo.synthFilters) && convo.synthFilters.length > 0) {
		const queryFilter = convo.synthFilters.find(([key]) => key.toLowerCase() === synthSettings.GENERATED_SEARCH_QUERY)
		if (queryFilter[1]) queryMessage = `View ${queryFilter[1].toLowerCase()}`
	}

	useEffect(() => {
		if (msg) {
			const detect = () => {
				const result = textRef?.current?.clientHeight > 108
				setIsMultiline(result)
			}

			resizeObserver.current = new ResizeObserver(detect)

			if (textRef.current instanceof Element) {
				resizeObserver.current.observe(textRef.current)
			}
		}
		return () => {
			if (resizeObserver.current && textRef.current instanceof Element) {
				resizeObserver.current.unobserve(textRef.current)
			}
		}
	}, [msg])

	// View Search Results handler
	const handleViewSearchResults = async () => {
		if (!convo.requestId || !hasSearchResults) return
		// Update search results
		let sr
		if (Array.isArray(convo.searchResults)) {
			sr = convo.searchResults
			setSearchResults(sr)
		} else {
			const endpoint = `${apiBaseUrl}/enterprise_session_turn/${convo.requestId}`
			try {
				setprevSrpRequestId(convo.requestId)
				const response = await fetch(endpoint, { method: 'GET', })
				const data = await response.json()

				if (Array.isArray(data)) {
					sr = data[0].search_results // get search results from the latest convo
					if (Array.isArray(sr)) {
						setSearchResults(sr)
					}
				}
				widgetLog('tt_click')
			} catch (err) {
				sentryUtils.captureApiError(err, endpoint)
			}
		}

		// If instant search is enabled, do not open the search results panel
		if (widgetSession.isInstantSearchEnabled) {
			return
		}
		if (Array.isArray(sr) && sr.length > 0) {
			handleOpenSR()
		} else if (Array.isArray(sr) && sr.length === 0 && isShowSRPanel) {
			handleCloseSR()
		}
	}

	const handleWebSearchClick = async () => {
		if (convo.isWebClicked) return
		try {
			await fetchWeb({ targetRequestId: convo.requestId })
			updateConvo({ requestId: convo.requestId, isWebClicked: true })
		} catch (err) {
			sentryUtils.captureError(err)
		}
	}

	const stopShowWeb = () => {
		updateConvo({ requestId: convo.requestId, isShowWebStopped: true })

	}

	const BubbleResponse = ({ textContent, SearchResultsQuery, WebButton, SourceDocs, SendUsMessage, bubbleWidth }) => <Box
		height="100%"
		className="msg__response_msg pb-3"
		display="flex"
		flexDirection="row"
		alignItems={isMultiline ? 'flex-start' : 'center'}
		onClick={handleViewSearchResults}
		cursor={hasSearchResults ? 'pointer' : 'auto'}
		ref={msgResponseRef}
		gap="9px"
		width={bubbleWidth}
	>
		<VStack display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" gap="8px" flex="1 0 0" maxWidth="301px" >
			<Box
				borderRadius="0px 12px 12px 12px"
				backgroundColor="rgba(255,255,255,0.07)"
				display="flex"
				padding="18px"
				flexDirection="column"
				justifyContent="center"
				alignItems="flex-start"
				gap="9px"
				flex="1 0 0"
				width={bubbleWidth}
				ref={textRef}
				{...(hasSearchResults && {
					_hover: { background: 'rgba(255, 255, 255, 0.05)', },
					_active: { background: 'rgba(255, 255, 255, 0.10)', },
					_focus: { background: 'rgba(255, 255, 255, 0.02)', textDecoration: 'none', boxShadow: 'none', },
				})}
			>
				<div className="text-markdown bot-response-markdown">
					{typeof textContent === 'string' ? (
						<ReactMarkdown
							children={textContent}
							components={{
								ol: ({ node, ...props }) => <ol style={{ paddingLeft: '18px' }} {...props} />,
								a: ({ node, ...props }) => (
									<span // Use a span instead of an anchor tag
										onClick={(event) => {
											event.preventDefault();
											handleOpenDoc(props.href, 0);
										}}
										style={{ color: 'var(--widget-link-color, #B794F4)', cursor: 'pointer' }}									>
										{props.children}
									</span>
								),
							}}
						/>
					) : (
						textContent
					)}
				</div>
				{SearchResultsQuery && <SearchResultsQuery />} {/* View Search Result */}
				{WebButton && <WebButton />}
				{SendUsMessage && <SendUsMessage />}
			</Box>
			{SourceDocs && <SourceDocs />}
			{/* {isEntLoading && isCurrent &&
				<Flex marginLeft="40px">
					<img src="/icon/spinner.gif" width="40px" height="40px" />
					<Flex marginLeft="-2" fontSize="xs" alignItems={"center"}>processing</Flex>
				</Flex>
			} */}
		</VStack>
	</Box>

	const checkIsWebSearchSuggested = (botResponse) => botResponse.toLowerCase().includes(convoSettings.WEB_SEARCH_KEYWORD) && botResponse[botResponse.length - 1] === '?'

	return (
		<>
			{!convo.isShowWebStopped &&
				<>
					{/* Web Search */}
					{convo.isWebLoading && <div className="pb-[9px]">
						<BotTyping avatar={avatar} />
						<div className="flex space-x-1 text-xs pt-1 pb-1 pl-[18px]">
							<div>searching the web ... it's a big place! </div>
							<div className="text-[#d96c6d] hover:cursor-pointer hover:opacity-80" onClick={stopShowWeb}>stop looking</div>
						</div>
					</div>
					}
				</>}
			{msg &&
				<div className="flex mb-[-9px]">
					<BubbleResponse textContent={msg} bubbleWidth={bubbleWidth}
						SearchResultsQuery={isSearchResultNotEmpty &&
							(() => <div className="text-[13px] font-normal leading-[22px]" style={{ color: widgetSession.appearanceConfig.linkColor }}>
								{queryMessage}
							</div>)
						}

						WebButton={isCurrent && checkIsWebSearchSuggested(convo.botResponse) && (
							!convo.isWebClicked ?
								() => <div className="py-1 px-3 rounded-full border-2 border-[#6b5983] hover:border-[#8a6fae] hover:bg-[#8a6fae] text-xs cursor-pointer" onClick={handleWebSearchClick}>
									{convoSettings.WEB_BUTTON_TEXT}
								</div>
								:
								() => <div className="py-1 px-3 rounded-full border-2 border-[#6b5983] text-xs text-gray-400">
									{convoSettings.WEB_BUTTON_TEXT}
								</div>
						)}

						SourceDocs={Array.isArray(convo.sourceDocs) && convo.sourceDocs.length > 0 && (
							(() => <DocChipList sourceDocs={convo.sourceDocs} handleOpenDoc={handleOpenDoc} />)
						)}
						SendUsMessage={widgetSession.appearanceConfig?.storePhone && msg.includes(widgetSession.appearanceConfig?.storePhone) && !convo.customerSupportForm && widgetSession.appearanceConfig?.supportEmail && (
							() => <div className="py-[3px] px-[9px] rounded-[32px] border-[1px] border-[rgba(170,133,217,0.50)] hover:border-[#8a6fae] hover:bg-[#8a6fae] text-[13px] font-normal cursor-pointer" onClick={() => { setContactSupportForm(true) }}>
								send us a message
							</div>
						)}
					/>
					{/* Only current message and non-welcome message have the thumb box*/}
					{isCurrent && !convo.isWelcomeMessage &&
						<div>
							<ThumbBox isPositive={true} thumbStatus={thumbStatus} setThumbStatus={setThumbStatus} convo={convo} />
							<ThumbBox isPositive={false} thumbStatus={thumbStatus} setThumbStatus={setThumbStatus} convo={convo} />
						</div>
					}

				</div>
			}
		</>
	)
}

export const transformBotMessage = (jsonStr) => {
	let data
	try {
		data = JSON.parse(jsonStr)
	} catch (e) {
		return jsonStr
	}

	// Check if "message", "link" and "text" exist
	if (data.message && data.link && data.link.text) {
		const { url, text } = data.link

		// Split the message into two parts: before and after the "text"
		let [beforeText, afterText] = data.message.split(text)

		// Construct the JSX
		return (
			<div>
				{beforeText}
				<a href={url} target="_blank" rel="noopener noreferrer" style={{ color: '#7EC9E1' }}>
					{text}
				</a>
				{afterText}
			</div>
		)
	}
	return data
}
