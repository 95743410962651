import React, { useEffect, useMemo, useRef, useState } from 'react'
// import axios from 'axios'
import { Box, ChakraProvider, Flex, GridItem, Image } from '@chakra-ui/react'
import { Worker } from '@react-pdf-viewer/core'
import { useShallow } from 'zustand/react/shallow'

import { Context, contextDefaultProperties } from './Context/Context.js'
import Documents from './components/Pdp/Documents/Documents.jsx'
import { Convo } from './components/Widget/Convo/Convo.jsx'
import { SynthFilters } from './components/Widget/SynthFilters.jsx'
import { WidgetInput } from './components/Widget/WidgetInput.jsx'
import { WidgetSearchResults } from './components/Widget/WidgetSearchResults.jsx'
import { synthSettings } from './settings/synth.settings.js'
import { widgetSettings } from './settings/widget.settings.js'
import { useStore } from './store/store.js'
import theme from './theme.js'
import { parentService } from './service/index.js'
import { X } from 'lucide-react'
import ContactForm from './components/Widget/ContactForm/ContactForm.jsx'
import HistoryForm from './components/Widget/ContactForm/HistoryForm.jsx'
import { ChatFooter } from './components/Widget/ChatFooter.jsx'

export const App = () => {
	const {
		searchResults,
		synthFilters,
		widgetSession,
		setWidgetSession,
		loadHistory,
		widgetLog,
		setPrompt,
		contactSupportForm,
		setContactSupportForm,
		sendHistoryForm,
		setSendHistoryForm,
	} = useStore(
		useShallow((state) => ({
			searchResults: state.searchResults,
			synthFilters: state.synthFilters,
			widgetSession: state.widgetSession,
			setWidgetSession: state.setWidgetSession,
			loadHistory: state.loadHistory,
			widgetLog: state.widgetLog,
			setPrompt: state.setPrompt,
			contactSupportForm: state.contactSupportForm,
			setContactSupportForm: state.setContactSupportForm,
			sendHistoryForm: state.sendHistoryForm,
			setSendHistoryForm: state.setSendHistoryForm,
		}))
	);

	const [writeMsg, setWriteMsg] = useState(false)
	const [searchResultTitle, setSearchResultTitle] = useState(false)
	const [bubbleWidth, setBubbleWidth] = useState(widgetSettings.CHAT_PANEL_DEFAULT_WIDTH - widgetSettings.CHAT_PANEL_RESPONSE_DIFF)

	const inputRef = useRef(null)
	const submitRef = useRef(null)
	const msgTopRef = useRef(null)
	const [loading, setLoading] = useState(false)

	const [isShowSRPanel, setIsShowSRPanel] = useState(false)
	// Using a reference to ensure the access to the most current state of the variable
	// Direct usage of isShowSRPanel might result in a stale state, causing improper visibility of the panel
	const isShowSRPanelRef = useRef(isShowSRPanel)
	const [isShowDocPanel, setShowDocPanel] = useState(false)
	// Using ref here for the same reason as above
	const isShowDocPanelRef = useRef(isShowDocPanel)

	const [rightPanelWidth, setRightPanelWidth] = useState(widgetSettings.DOC_PANEL_DEFAULT_WIDTH)
	const [chosenDocUrl, setChosenDocUrl] = useState(null)
	const [pageIndex, setPageIndex] = useState(0)

	const chatPanelRef = useRef(null)
	const refinedSynthFilters = synthFilters.filter(([key]) => key.toLowerCase() !== synthSettings.GENERATED_SEARCH_QUERY)

	const scrollToTop = (ref) => {
		ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', })
	}

	const requestSession = () => {
		window.parent.postMessage({ action: 'cv-request-session' }, '*')
	}

	useEffect(() => {
		isShowSRPanelRef.current = isShowSRPanel;
	}, [isShowSRPanel]);


	useEffect(() => {
		isShowDocPanelRef.current = isShowDocPanel;
	}, [isShowDocPanel]);

	const handleOpenSR = () => {
		if (isShowSRPanelRef.current === false) {
			handleCloseDoc() // Open one, close another
			setIsShowSRPanel(true)
			window.parent.postMessage({
				action: 'cv-iframe-resize',
				width: chatPanelRef.current.offsetWidth + widgetSettings.SR_PANEL_DEFAULT_WIDTH,
				rightPanelWidth: widgetSettings.SR_PANEL_DEFAULT_WIDTH,
			}, '*',)
		}
	}

	const handleCloseSR = () => {
		if (isShowSRPanelRef.current === true) {
			widgetLog('search_close')
			window.parent.postMessage(
				{
					action: 'cv-iframe-resize', width: chatPanelRef.current.offsetWidth,
					rightPanelWidth: 0,

				},
				'*',
			)
			setIsShowSRPanel(false)
		}
	}

	const handleOpenDoc = (fileUrl, chosenIndex) => {
		if (fileUrl) {
			widgetLog('attribution_click')
			handleCloseSR() // Open one, close another 	
			setTimeout(() => {
				setPageIndex(chosenIndex)
				setChosenDocUrl(fileUrl)
				// Only check this condition after updating the page index because the doc panel might open already
				// Otherwise, it cannot update the page			
				if (isShowDocPanelRef.current === false) {
					setShowDocPanel(true)
					window.parent.postMessage({
						action: 'cv-iframe-resize',
						width: chatPanelRef.current.offsetWidth + rightPanelWidth,
						rightPanelWidth: widgetSettings.DOC_PANEL_DEFAULT_WIDTH,
					}, '*',)
				}
			}, 500) // 0.5s timeout for the open animaiton to finish
		}
	}

	const handleCloseDoc = () => {
		if (isShowDocPanelRef.current === true) {
			window.parent.postMessage({
				action: 'cv-iframe-resize',
				width: chatPanelRef.current.offsetWidth,
				rightPanelWidth: 0,
			}, '*')
			setShowDocPanel(false)
			setTimeout(() => {
				setChosenDocUrl(null) // Resetting the doc is necessary for the document component to unmount & load properly later.
				setPageIndex(0)
			}, 500) // 0.5s delay for the close animation to finish
			widgetLog('pdf_close')
		}
	}

	// ------------------------- LISTENING TO PARENT'S MESSAGES ------------------------- //
	useEffect(() => {
		// Listening to parent window
		window.addEventListener('message', async (event) => {
			if (event.data.action === 'cv-session-response') {
				if (event.data.shouldLoadHistory) { // only load history the first time this action happens
					// Retry if failed to account for BE's delay when switching page
					async function loadHistoryWithRetry(id, maxRetries = 10, interval = 1000) {
						let retries = 0;
						let history = [];

						while (retries < maxRetries) {
							try {
								history = await loadHistory(id);
								break;
							} catch (error) {
								retries++;
								if (retries >= maxRetries) {
									throw new Error(`Failed to load history after ${maxRetries} attempts: ${error.message}`);
								}
								await new Promise(resolve => setTimeout(resolve, interval));
							}
						}

						return history;
					}
					await loadHistoryWithRetry(event.data.id)
				}

				// Setting up the stream to receive session information from the parent.
				setWidgetSession(event.data)
				const rightPanelMaxWidth = Math.min(
					widgetSettings.DOC_PANEL_DEFAULT_WIDTH,
					event.data.screenSize.width - widgetSettings.CHAT_PANEL_MIN_WIDTH,
				)
				setRightPanelWidth(rightPanelMaxWidth)
			}

			if (event.data.action === 'cv-open-widget') {
				if (widgetSession.hasParent !== true) {
					inputRef.current && inputRef.current.focus()
				}
			}

			if (event.data.action === 'cv-close-widget') {
				handleCloseDoc()
				handleCloseSR()
			}
		})
		// Notify the merchant store that the page is ready by requesting the session
		requestSession()
	}, [])

	// Resize the chat panel based on the container resize event 
	window.addEventListener('message', (event) => {
		if (event.data.type === 'cv-container-resize') {
			const rightPanelWidth = isShowSRPanel
				? widgetSettings.SR_PANEL_DEFAULT_WIDTH
				: isShowDocPanel
					? widgetSettings.DOC_PANEL_DEFAULT_WIDTH
					: 0
			const newChatPanelWidth = event.data.width - rightPanelWidth
			chatPanelRef.current.style.width = `${newChatPanelWidth}px`
			setBubbleWidth(newChatPanelWidth - widgetSettings.CHAT_PANEL_RESPONSE_DIFF)
		}
	})

	const reset = () => {
		setPrompt('')
		setWriteMsg(false)
		handleCloseDoc()
		widgetLog('clear_convo')
		parentService.resetSession()

		if (Object.keys(widgetSession).length === 0) return
		// Generating the URL for reloading
		let parentUrl = new URL(widgetSession.url);
		// Remove sessionId from URL because sessionId is used to restore old session
		// and here we want to create a new session.
		parentUrl.searchParams.delete('sessionId');
		let reloadUrl = parentUrl.toString()
		// This was cusotmized for the morphbot, but we probably don't need it anymore
		// if (widgetSession.isGhost === true) {
		// 	let ghostDomain = new URL(widgetSession.ghostDomain)
		// 	ghostDomain.searchParams.set('address', reloadUrl);
		// 	reloadUrl = ghostDomain.toString()
		// }
		window.parent.location.href = reloadUrl.toString();
	}

	const contextValue = useMemo(
		() => ({ ...contextDefaultProperties, writeMsg, setWriteMsg, searchResultTitle, setSearchResultTitle, scrollToTop, loading, setLoading, reset, handleOpenDoc, widgetLog, }),
		[scrollToTop, writeMsg, searchResultTitle, loading, reset, handleOpenDoc, widgetLog],
	)

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
			<ChakraProvider theme={theme}>
				<Context.Provider value={contextValue}>
					<Box className="main__page">
						<div className="h-screen overflow-hidden grid grid-cols-[auto_auto]" name="chat-panels">

							{/* Contact Support Form */}
							{contactSupportForm && (
								<Box name="chatPanel" overflow="" height="100vh" width={widgetSettings.CHAT_PANEL_MIN_WIDTH} bg="bg.black" ref={chatPanelRef} position="relative" >
									<div className="flex justify-between items-center pt-[29.5px] pb-[30.5px] px-[20px]">
										<h3 className='text-white text-lg font-semibold'>{`${widgetSession.appearanceConfig?.merchantName} customer support`}</h3>
										<div className='p-[7px] cursor-pointer text-[#FFFFFF]' onClick={() => setContactSupportForm(false)}>
											<X size={'18'} />			{/*need to update the size */}
										</div>
									</div>

									<div>
										<ContactForm />
									</div>
								</Box>
							)}

							{/* History Form */}
							{sendHistoryForm && (
								<Box name="chatPanel" overflow="" height="100vh" width={widgetSettings.CHAT_PANEL_MIN_WIDTH} bg="bg.black" ref={chatPanelRef} position="relative" >
									<div className="flex justify-between items-center pt-[29.5px] pb-[30.5px] px-[20px]">
										<h3 className='text-white text-lg font-semibold'>{`Share your conversation history`}</h3>
										<div className='p-[7px] cursor-pointer text-[#FFFFFF]' onClick={() => setSendHistoryForm(false)}>
											<X size={'18'} />			{/*need to update the size */}
										</div>
									</div>

									<div>
										<HistoryForm />
									</div>
								</Box>
							)}

							{/* Main Chat Section */}
							{!contactSupportForm && !sendHistoryForm && (
								<Box name="chatPanel" overflow="" height="100vh" width={widgetSettings.CHAT_PANEL_MIN_WIDTH} bg="bg.black" ref={chatPanelRef} position="relative" className="flex flex-col">

									<WidgetInput
										msgTopRef={msgTopRef}
										ref={inputRef}
										submitRef={submitRef}
										isEnterpriseQna={true}
										requestSession={requestSession}
										isShowSRPanel={isShowSRPanel}
										isShowDocPanel={isShowDocPanel}
									/>

									{/* Chat */}
									<div className="flex flex-col min-h-[calc(100vh-105px)] items-start p-[0_21px] relative text-white bg-[#272727] flex-grow">
										<div className="chat__wrapper w-full flex-grow">
											<div className="chat__main">
												<Convo
													inputRef={inputRef}
													submitRef={submitRef}
													bubbleWidth={bubbleWidth}
													avatar={widgetSession.appearanceConfig?.avatar}
													handleOpenSR={handleOpenSR}
													handleCloseSR={handleCloseSR}
												/>
											</div>
										</div>

										{/* Synthesis Panel */}
										{refinedSynthFilters?.length > 0 && (
											<SynthFilters refinedSynthFilters={refinedSynthFilters} />
										)}

										{/* Chat Footer */}
										<ChatFooter domain={widgetSession.domain} />
									</div>
								</Box>
							)}

							{/* Search Result Panel */}
							{widgetSession.isInstantSearchEnabled === false && Array.isArray(searchResults) && searchResults.length > 0 && isShowSRPanel &&
								<WidgetSearchResults widgetSession={widgetSession} searchResults={searchResults} isShowSRPanel={isShowSRPanel} handleCloseSR={handleCloseSR} />
							}

							{/* Source Docs Panel */}
							{chosenDocUrl && (
								<Box width={isShowDocPanel ? rightPanelWidth : 0} overflow="hidden" height="100vh" name="rightPanel" bg="bg.darkGrey" padding="0 18px"
									transition="width 0.5s cubic-bezier(0.820, 0.085, 0.395, 0.895)" >
									<>
										<Box display="flex" justifyContent={'flex-end'} padding={'1.1rem 1rem 0'}>
											<Image src="/icon/close-dark.png" alt="Close PDF Button" cursor="pointer" onClick={handleCloseDoc} />
										</Box>

										<Documents
											docUrl={chosenDocUrl}
											pageIndex={pageIndex}
											setPageIndex={setPageIndex}
											isEnterpriseQna={true} theme="dark" />
									</>
								</Box>
							)}
						</div>
					</Box >
				</Context.Provider >
			</ChakraProvider >
		</Worker >
	)
}

