import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { BotResponse } from './BotResponse.jsx'

import { Box, useDisclosure, } from '@chakra-ui/react'
import { useShallow } from 'zustand/react/shallow'

import { Context } from '../../../Context/Context.js'
import { useStore } from '../../../store/store.js'
import { ConvoClearModal } from './ConvoClearModal.jsx'
import { ConvoHeader } from './ConvoHeader.jsx'
import { UserPrompt } from '../UserPrompt.jsx'
import { BotTyping } from '../../Animation/TypingWithIcon/BotTyping'
import { UserTyping } from '../../Animation/TypingWithIcon/UserTyping'
import { synthSettings } from '../../../settings/synth.settings.js'
import { hoverScrollbarStyle } from '../../../Common/commonStyles.js'

export const Convo = React.forwardRef(({ avatar, inputRef, bubbleWidth, handleOpenSR, handleCloseSR }) => {
	const [convos, appendConvos, widgetSession, setPrompt] = useStore(useShallow(state => [
		state.convos, state.appendConvos, state.widgetSession, state.setPrompt]))
	const isLoadingHistory = useStore(state => state.isLoadingHistory)
	const synthFilters = useStore(state => state.synthFilters)
	const {
		writeMsg,
		reset,
		loading: isReplying,
		setShowPdp,
		handleOpenDoc,
	} = useContext(Context)

	useEffect(() => {
		// Only execute once and skip when the product name is not determined yet (during early page load)
		if (convos.length !== 0 || widgetSession.productName === undefined) return
		const welcomeMsg = widgetSession.productName
			? (`You can ask me any questions about the ${widgetSession.productName}.`)
			: `I'm Conversant, your AI assistant for seamless product discovery. Search, ask questions, and explore detailed product information through this conversational interface.
`
		appendConvos({
			isWelcomeMessage: true,
			botResponse: welcomeMsg,
			timestamp: new Date().toISOString(),
			sessionId: widgetSession.id,
			requestId: '',
			page_url: window.location.href,
			userPrompt: '',
			product_name: widgetSession.productName,
			product_sku: widgetSession.productSku,
		})
	}, [JSON.stringify(widgetSession)])


	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleModalClose = () => {
		onClose()

		setTimeout(() => {
			inputRef.current?.focus()
		}, 500)
	}

	const onHandleResetClick = () => {
		setShowPdp(false)
		reset()
		handleModalClose()
	}

	const msgsContainerRef = useRef(null)
	const [hasScrollbar, setHasScrollbar] = useState(false)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)

	useEffect(() => {
		const msgsContainerElement = msgsContainerRef.current
		setHasScrollbar(msgsContainerElement.scrollHeight > msgsContainerElement.clientHeight)
	}, [JSON.stringify(convos)])

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	let chatHeight = windowHeight - 100
	const refinedSynthFilters = synthFilters.filter(([key]) => key.toLowerCase() !== synthSettings.GENERATED_SEARCH_QUERY)
	if (refinedSynthFilters.length > 0) chatHeight = chatHeight - 150

	return (
		<Box
			className="msg__main"
			css={{
				position: 'relative',
				height: `${chatHeight}px`,
				minHeight: '503px',
				overflow: 'auto', // Important to make the content scrollable
				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					height: '56px', // Adjust this value for the height of the gradient
					width: '100%',
					background:
						'linear-gradient(to bottom, rgba(39,39,39,0) 0%,rgba(39,39,39,1) 100%)' /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */,
					filter:
						"progid:DXImageTransform.Microsoft.gradient( startColorstr='#00323232', endColorstr='#323232',GradientType=0 )" /* IE6-9 */,
					zIndex: 2, // Ensure the gradient appears above the content
				},
			}}
		>
			<ConvoHeader isLoading={isReplying} onOpen={onOpen} />
			{writeMsg && <UserTyping avatar={avatar} hasScrollbar={hasScrollbar} />}
			{isReplying && <BotTyping avatar={avatar} hasScrollbar={hasScrollbar} />}
			<Box
				name="conv-scroll-container"
				ref={msgsContainerRef}
				className="overflow-y-scroll overflow-x-hidden mb-4"
				css={hoverScrollbarStyle}
			>
				<Box className="pb-4">
					{!isLoadingHistory && Array.isArray(convos) && convos?.map((convo, index) => {
						return (
							<Fragment key={convo.requestId || index}>
								<Box
									name="convo-message"
									className="msg__response message mb-1 mt-1"
								>
									{convo.customerSupportForm &&
										<div>
											<p className='text-[rgba(255,255,255,0.55)] text-center text-xs font-normal leading-4 pb-2'>
												{convo.customerSupportForm}
											</p>
										</div>
									}
									{(convo.botResponse) &&
										<BotResponse
											convo={convo}
											isCurrent={index === 0}
											handleOpenDoc={handleOpenDoc}
											bubbleWidth={bubbleWidth}
											handleOpenSR={handleOpenSR}
											handleCloseSR={handleCloseSR}
										/>}
									{convo.userPrompt && <UserPrompt prompt={convo.userPrompt} />}
								</Box>
							</Fragment>
						)
					})}
				</Box>
			</Box>
			<ConvoClearModal isOpen={isOpen} handleModalClose={handleModalClose} onHandleResetClick={onHandleResetClick} />
		</Box>
	)
})