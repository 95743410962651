import React from 'react'
import { truncateString } from '../../lib/utils'
import { hoverScrollbarStyle } from '../../Common/commonStyles.js'
export const SynthFilters = ({ refinedSynthFilters }) => {

	return (
		<div
			name="synth-panel"
			className="mt-[11px] pb-[18px]"
			style={hoverScrollbarStyle}
			onMouseEnter={(e) => e.currentTarget.style.WebkitMaskPosition = 'left top'}
			onMouseLeave={(e) => e.currentTarget.style.WebkitMaskPosition = 'left bottom'}
		>
			<div className="text-lg leading-[48px]">You are looking for...</div>
			<div className="overflow-y-scroll max-h-[113px]">
				{refinedSynthFilters.map(([key, value], index) => {
					return <div key={key + '-' + index} className="border-b border-[#323232]">
						<div className="flex space-x-4">
							<div className="w-[200px] h-[32px] text-[12px] flex flex-col justify-center">
								{key.toUpperCase()}
							</div>
							<div className="h-[32px] text-[13px] flex flex-col justify-center">
								{truncateString(value, 35)}
							</div>
						</div>
					</div>
				})}
			</div>
		</div>
	)
}
