const hoverScrollbarStyle = {
    overflowY: 'auto',
    overflowX: 'hidden',
    maskImage:
        'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 10px, black 10px)',
    maskSize: '100% 100000px',
    maskPosition: 'left bottom',
    WebkitMaskImage:
        'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 10px, black 10px)',
    WebkitMaskSize: '100% 100000px',
    WebkitMaskPosition: 'left bottom',
    transition: 'mask-position 0.3s, -webkit-mask-position 0.3s',
    '&:hover': {
        WebkitMaskPosition: 'left top',
    },
    '&::-webkit-scrollbar': {
        display: 'block',
    },
}

export { hoverScrollbarStyle };